/*
 |-----------------------------------------------------------------------------
 | components/molecules/Navbar/Navbar.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';

import HeaderMenu from '@/molecules/HeaderMenu';
import { menuSelector } from '@/features/menu';
import { useAppSelector } from '@/app/hooks';

import * as INavbar from './types';

const Navbar: FC<INavbar.IProps> = ({
	darkMode,
	menu,
	storybook,
	utilities = '',
}) => {
	// ray('Debug molecule Navbar:', {
	// 	darkMode: darkMode,
	// 	menu: menu,
	// 	utilities: utilities,
	// }).blue();

	const menuState = useAppSelector(menuSelector);

	return (
		<nav
			className={`
				md:bg-teal-500
				md:fixed
				md:bottom-0
				md:p-4
				md:top-[80px]
				md:transition-[right]
				md:w-[375px]
				${menuState || storybook ? `md:right-0` : `md:right-[-375px]`}
				${utilities}
			`}
			itemScope
			itemType="http://schema.org/SiteNavigationElement"
		>
			{menu && (
				<HeaderMenu
					darkMode={darkMode}
					items={get(menu, 'items')}
					label={get(menu, 'label')}
				/>
			)}
		</nav>
	);
};

export default Navbar;

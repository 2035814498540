/*
 |-----------------------------------------------------------------------------
 | components/organisms/Header/Header.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC, useEffect, useState } from 'react';

import Link from 'next/link';
import { get } from 'lodash';
// import { ray } from 'node-ray/web';

import Container from '@/layouts/Container';
import Hamburger from '@/atoms/Hamburger';
import Logo from '@/atoms/Logo';
import Navbar from '@/molecules/Navbar';
import { getMenu } from '@/utils/menus';
import { menuSelector } from '@/features/menu';
import { menusSelector } from '@/features/menus';
import { organisationSelector } from '@/features/organisation';
import { useAppSelector } from '@/app/hooks';

import * as IHeader from './types';

const Header: FC<IHeader.IProps> = ({ darkMode }) => {
	// ray('Debug organism Header:', {
	// 	darkMode: darkMode,
	// }).purple();

	const menus = useAppSelector(menusSelector);
	const menuState = useAppSelector(menuSelector);
	const organisation = useAppSelector(organisationSelector);

	const [isHidden, setIsHidden] = useState<boolean>(false);
	const [isSticky, setIsSticky] = useState<boolean>(false);

	useEffect(() => {
		let lastScrollTop = scrollY;
		window.addEventListener(
			'scroll',
			() => {
				const scrollTop =
					window.scrollY || document.documentElement.scrollTop;

				switch (true) {
					// Top
					case scrollTop === 0:
						setIsHidden(false);
						setIsSticky(false);
						break;
					// Scrolling down
					case scrollTop > lastScrollTop:
						setIsHidden(true);
						setIsSticky(false);
						break;
					// Scrolling up
					case scrollTop < lastScrollTop:
						setIsHidden(false);
						setIsSticky(true);
						break;
				}

				lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
			},
			false
		);
	}, []);

	const menu = getMenu('header', menus);

	return (
		<header
			className={`
				py-4
				sticky
				top-0
				transition-opacity
				z-40
				${isHidden ? (menuState ? 'opacity-100' : 'opacity-0') : 'opacity-100'}
				${isSticky ? (darkMode ? 'bg-white' : 'bg-black') : ''}
				${menuState ? 'bg-teal-500' : ''}
			`}
			itemScope
			itemType="http://schema.org/Header"
		>
			<Container utilities="flex items-center justify-between">
				<Link href="/" passHref>
					<a
						href="jsx-a11y"
						itemScope
						itemType="http://schema.org/Organization"
						rel="home"
						title="Back to Home"
					>
						<Logo
							darkMode={darkMode}
							name={get(organisation, 'name')}
							utilities={`h-[48px] w-[200px]`}
						/>
					</a>
				</Link>

				<Hamburger
					closeLabel="Close menu"
					darkMode={darkMode}
					openLabel="Open menu"
					utilities="hidden md:flex"
				/>

				<Navbar darkMode={darkMode} menu={menu} />
			</Container>
		</header>
	);
};

export default Header;
